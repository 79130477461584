import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EMAIL_INVALID_TEXTS, API_FORGOT_PASSWORD_PATH } from '../utils/Constants';


function ForgotPasswordPage() {
    const navigate = useNavigate("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState({ visible: false, text: "", success: false })
    const [loading, setLoading] = useState("");
    const [messageTimout, setMessageTimout] = useState(null);


    useEffect(() => {
        if (message.visible) {
            if (messageTimout !== null) clearTimeout(messageTimout)
            setMessageTimout(
                setTimeout(() => {
                    setMessage({
                        visible: false,
                        text: "",
                        success: false,
                    })
                }, 4000)
            )
        }
    }, [message])


    function isValidEmail(value) {
        return /\S+@\S+\.\S+/.test(value);
    }


    function onEmailChange(e) {
        setEmail(e.target.value);
        const emailValid = isValidEmail(e.target.value)
        setMessage({
            visible: !emailValid,
            text: EMAIL_INVALID_TEXTS,
            success: emailValid,
        })
    }


    async function processRecoverPassword() {
        if (email === "" || !isValidEmail(email)) return;
        setLoading(true);
        var response = null;
        try {
            response = await axios.post(
                API_FORGOT_PASSWORD_PATH,
                { email: email },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                },
            )

        } catch (error) {
            response = error.response
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
                setEmail("")
                if (response.status === 200) {
                    console.log(response)
                }
            }
            setLoading(false)
        }

    }



    return (
        <>
            <div className="auth-lady">
                <img src="./images/auth-lady.png" alt="auth-lady" />
            </div>
            <main>
                <div className="content text-center">
                    <h1 className="text-white">KISTT</h1>
                </div>
                <div className="auth-form" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="content text-center text-theme">
                        <h2>Password Recovery</h2>
                    </div>
                    <form>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }} >
                                <i className="fa-solid fa-envelope"></i>
                            </span>
                            <input type="email"
                                value={email} onChange={onEmailChange}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Email address" aria-label="Email address" />
                        </div>
                        <p className="text-center mt-3">By providing your email, we will send a recovery email to your inbox and you can assign a new password by hitting the link in the email</p>
                        <button type="button" onClick={processRecoverPassword} disabled={loading} className="btn btn-theme w-100">
                            {
                                loading ?
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    : <span>Recover Password</span>
                            }
                        </button>
                    </form>
                    <div className="other-signin text-center">
                        <a href="/login.php"><strong>Login here</strong></a>
                    </div>
                </div>
            </main>
        </>
    );
}


export default ForgotPasswordPage;
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PasswordInput from '../components/PasswordInput';
import { EMAIL_INVALID_TEXTS, API_SIGNUP_PATH, TIME_TO_STAY_BEFORE_REDIRECT, API_SOCIAL_LOGIN_PATH, GOOGLE_CLIENT_ID, GOOGLE_SOCIAL_LOGIN_TYPE, FACEBOOK_SOCIAL_LOGIN_TYPE } from '../utils/Constants';
import Cookies from 'universal-cookie';
import { LoginStatus, useLogin } from 'react-facebook';
import { getFbUser, getGoogleUser } from '../utils/SocialAuth';



function SignupPage() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState("");
    const [message, setMessage] = useState({ visible: false, text: "", success: false });
    const [messageTimout, setMessageTimout] = useState(null);
    const [profileImage, setProfileImage] = useState({ url: null, blob: null });
    const profileImageRef = useRef();
    const facebookLogin = useLogin();


    useEffect(() => {
        if (message.visible) {
            if (messageTimout !== null) clearTimeout(messageTimout)
            setMessageTimout(
                setTimeout(() => {
                    setMessage({
                        visible: false,
                        text: "",
                        success: false,
                    })
                }, 4000)
            )
        }
    }, [message])

    function isValidEmail(value) {
        return /\S+@\S+\.\S+/.test(value);
    }


    function onEmailChange(e) {
        setEmail(e.target.value);
        const emailValid = isValidEmail(e.target.value)
        setMessage({
            visible: !emailValid,
            text: EMAIL_INVALID_TEXTS,
            success: emailValid,
        })
    }


    function onProfileImageChange(e) {
        const files = e.target.files;
        if (files.length <= 0) return;
        setProfileImage({
            url: window.URL.createObjectURL(files[0]),
            blob: files[0],
        });
    }


    async function continueWithFacebook() {
        setLoading(true);
        try {
            const response = await facebookLogin.login({
                scope: 'email,public_profile',
                returnScopes: true
            });
            console.log(response);
            if (response.status === LoginStatus.CONNECTED) {
                let fbUser = await getFbUser(response.authResponse.accessToken);
                _handleSignup({
                    type: FACEBOOK_SOCIAL_LOGIN_TYPE,
                    facebook_id: response.authResponse.userID,
                    name: fbUser.name,
                }, API_SOCIAL_LOGIN_PATH, "application/json");
            } else {
                setMessage({
                    text: "Got error while authenticating with facebook.",
                    success: false,
                })
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    function continueWithGoogle() {
        setLoading(true);
        const client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: [
                "https://www.googleapis.com/auth/userinfo.profile",
                "https://www.googleapis.com/auth/userinfo.email",
            ].join(" "),
            ux_mode: 'popup',
            callback: async (response) => {
                console.log(response)
                const googleUser = await getGoogleUser(response.access_token)
                if (googleUser !== null) {
                    console.log(googleUser);
                    _handleSignup({
                        type: GOOGLE_SOCIAL_LOGIN_TYPE,
                        google_id: googleUser.sub,
                        name: googleUser.name,
                        email: googleUser.email,
                    }, API_SOCIAL_LOGIN_PATH);
                } else {
                    setMessage({
                        text: "Got error while authenticating with google.",
                        success: false,
                    })
                }
            }
        });
        client.requestAccessToken({ prompt: "consent" });
    }


    async function _handleSignup(body, path, contentType = "multipart/form-data") {
        var response = null;
        try {
            response = await axios.post(
                path, body,
                {
                    headers: {
                        "Content-Type": contentType,
                    },
                },
            )
            console.log(response)
        } catch (error) {
            console.log(error.response)
            response = error.response
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
                if (response.status === 200) {
                    setEmail("");
                    let expireDate = new Date()
                    expireDate.setDate(expireDate.getDate() + 1);
                    cookies.set(
                        "user", response.data.data,
                        {
                            secure: true, path: "*",
                            sameSite: "strict", expires: expireDate,
                            maxAge: 60 * 60 * 24,
                        }
                    )
                    cookies.set(
                        "access_token", response.data.data.access_token,
                        {
                            secure: true, path: "*",
                            sameSite: "strict", expires: expireDate,
                            maxAge: 60 * 60 * 24,
                        }
                    )
                    setTimeout(() => {
                        navigate("/");
                    }, TIME_TO_STAY_BEFORE_REDIRECT);
                }
            }
            setPassword("");
            setLoading(false)
        }
    }



    async function processSignup() {
        if (name === "" || password === "" || email === "" || !isValidEmail(email)) return;
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        if (profileImage.blob !== null) formData.append("image", profileImage.blob);
        _handleSignup(formData, API_SIGNUP_PATH)
    }


    return (
        <>
            <div className="auth-lady">
                <img src="./images/auth-lady.png" alt="auth-lady" />
            </div>
            <main>
                <div className="content text-center">
                    <Link to="/" className='text-decoration-none'><h1 className="text-white">KISTT</h1></Link>
                </div>
                <div className="auth-form" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="content text-center text-theme">
                        <h2>Welcome!</h2>
                        <p>Sign up here</p>
                    </div>
                    <div className='add-user'>
                        <input type="file" ref={profileImageRef} onChange={onProfileImageChange} accept='image/*' className='d-none' />
                        <div className='cursor-pointer'>
                            {
                                profileImage.url === null || profileImage.url === "" ?
                                    <div className="user-icon" onClick={() => profileImageRef.current.click()}>
                                        <i className="fa-solid text-theme fa-user-plus"></i>
                                    </div>
                                    : <img src={profileImage.url} width={120} height={120}
                                        onClick={() => profileImageRef.current.click()} alt="User Profile"
                                        className="rounded-circle bg-light border border-4 cursor-pointer"
                                        style={{ objectFit: "cover", objectPosition: "center" }} />
                            }
                        </div>
                    </div>
                    <form>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }}>
                                <i className="fa-solid fa-user"></i>
                            </span>
                            <input type="text"
                                value={name} onChange={(e) => setName(e.target.value)}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Full Name" aria-label="Full Name" />
                        </div>
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3 my-4">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }}>
                                <i className="fa-solid fa-envelope"></i>
                            </span>
                            <input type="email"
                                value={email} onChange={onEmailChange}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Email address" aria-label="Email address" />
                        </div>
                        <PasswordInput value={password} onChange={setPassword} className="my-4" placeholder={"Password"} />
                        <p className="text-center">By signing up you indicate that you have read and <br /> agreed to the <a href='#'>Terms of Service</a></p>
                        <button type="button" onClick={processSignup} disabled={loading} className="btn btn-theme w-100">
                            {
                                loading ?
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    : <span>Sign Up</span>
                            }
                        </button>
                    </form>
                    <div className="other-signin text-center">
                        <Link to="/login"><strong>Already a member? Login here</strong></Link>
                        <div className="or-section">
                            <p>Or continue with</p>
                            <ul>
                                <li><a onClick={continueWithGoogle} style={{ cursor: "pointer" }}><img src="./images/google.png" alt="google" /></a></li>
                                <li><a onClick={continueWithFacebook} style={{ cursor: "pointer" }}><img src="./images/facebook.png" alt="facebook" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}


export default SignupPage;
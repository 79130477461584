import React, { useEffect, useState } from 'react';
import useAuth from '../utils/AuthHooks';
import { useParams, useNavigate } from 'react-router-dom';
import UpdateModelModal from '../modals/UpdateModelModal';
import { Delete3DModel, GetAllModels } from '../utils/ApiModelEndpoints';
import MessageComponent from '../components/MessageComponent';




function PhotosPage() {
    const navigate = useNavigate();
    const { accessToken } = useAuth();
    const [modelsList, setModelsList] = useState(null);
    const modelId = parseInt(useParams().modelId);
    const [updateModelModal, setUpdateModelModal] = useState({
        visible: !isNaN(modelId),
        modelId: modelId
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ text: "", success: false });

    async function fetchModels() {
        let _models = await GetAllModels(accessToken);
        setModelsList(_models);
    }

    useEffect(() => {
        fetchModels();
    }, [accessToken]);


    async function deleteModel(modelId) {
        setLoading(true);
        const { success, response } = await Delete3DModel(accessToken, modelId);
        setMessage({ text: response, success: success })
        await fetchModels();
        setLoading(false)
    }



    return (
        <>
            <main className={updateModelModal.visible ? "blur" : ""}>
                <div className="container-fluid">
                    <div className="content">
                        <h2>3D Photos</h2>
                    </div>
                    <MessageComponent message={message} setMessage={setMessage} />
                    {
                        modelsList === null || loading
                            ? <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "500px" }}>
                                <div className='spinner-border' style={{ width: 60, height: 60, aspectRatio: 1 }}></div>
                            </div>
                            : <div className="photo-gallery">
                                <ul>
                                    {
                                        modelsList.map((model) => (
                                            <li className='shadow-simple' style={{ position: 'relative' }} key={model.id}>
                                                <button type='button' onClick={() => deleteModel(model.id)}
                                                    className='btn p-2 btn-outline-danger d-flex align-items-center justify-content-center'
                                                    style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
                                                    <i className='fs-6 fa-solid fa-trash'></i>
                                                </button>
                                                <a href={model.ThreedPath + "?isWebApp=1"}>
                                                    {
                                                        model.images.length >= 1 &&
                                                        <img src={model.images[0].path} alt="Model Thumbnail Image" />
                                                    }
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                    }
                </div>
            </main>
            <UpdateModelModal
                modal={updateModelModal}
                onCancel={() => {
                    window.history.back();
                    setUpdateModelModal({ visible: false, model: null, modelId: NaN });
                }} />
        </>
    );
}

export default PhotosPage;
import React, { useState } from "react";



function PasswordInput({ className, value, onChange, placeholder }) {
    const [visible, setVisible] = useState(false);
    return (
        <div className={"input-group flex-nowrap shadow-simple p-3 rounded-3 " + className}>
            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }} >
                <i className="fa-solid fa-lock"></i>
            </span>
            <input type={visible ? "text" : "password"}
                value={value} onChange={(e) => onChange(e.target.value)}
                className="form-control text-muted border-0 shadow-none"
                placeholder={placeholder} aria-label="Password" />
            <span
                className="input-group-text text-muted ms-1 d-flex rounded-circle btn btn-light border-0 p-0 px-2"
                onClick={() => setVisible(!visible)}>
                <i className={"fa-solid " + (visible ? "fa-eye-slash" : "fa-eye")}></i>
            </span>
        </div>
    );
}

export default PasswordInput;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';






function LogoutPage() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(() => {
        cookies.remove("access_token");
        cookies.remove("user");
        navigate("/login?callback=/");
    })

}


export default LogoutPage;
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import ProtectedLayout from './components/ProtectedLayout';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import PhotosPage from './pages/PhotosPage';
import SettingsPage from './pages/SettingsPage';
import SignupPage from './pages/SignupPage';


// const showHeaderPaths = ["/login", "/signup", "/forgot-password", "/logout", "/settings/change-password"];
const showHeaderPaths = ["/", "/photos", "/settings", "/settings/change-password/", "/photos/*/edit", "/threed-models/*/edit"];

function headerEnabled(pathname) {
  for (const path of showHeaderPaths) {
    const regex = new RegExp('^' + path.replace('*', '.*') + '$');
    if (regex.test(pathname)) {
      return true;
    }
  }
}

function App() {
  const { pathname } = useLocation();

  return (
    <div className={!headerEnabled(pathname) ? "auth-page" : "page-layout"}>
      {headerEnabled(pathname) && <Header pathname={pathname} />}
      <Routes>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/photos" element={<PhotosPage />} />
          <Route path="/photos/:modelId/edit" element={<PhotosPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/settings/change-password" element={<ChangePasswordPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </Route>

        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAuth from "../utils/AuthHooks";
import { API_NOTIFICATION_STATUS_PATH, TIME_TO_STAY_BEFORE_REDIRECT } from "../utils/Constants";




function NotificationStatusModal({ modal, onSuccess, onCancel }) {
    const { accessToken } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState("");
    const [notificationsEnabled, setNotificationsEnabled] = useState(modal.enabled);
    const [message, setMessage] = useState({ visible: false, text: "", success: false })
    const [messageTimout, setMessageTimout] = useState(null);


    useEffect(() => {
        if (message.visible) {
            if (messageTimout !== null) clearTimeout(messageTimout)
            setMessageTimout(
                setTimeout(() => {
                    setMessage({
                        visible: false,
                        text: "",
                        success: false,
                    })
                }, 4000)
            )
        }
    }, [message]);




    async function updateNotificationsStatus() {
        setLoading(true);
        const body = {
            notification_status: notificationsEnabled ? 1 : 0
        }
        var response = null;
        try {
            response = await axios.post(
                API_NOTIFICATION_STATUS_PATH,
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken
                    },
                },
            )

        } catch (error) {
            response = error.response
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
            }
            setLoading(false)
        }

    }




    return (
        <>
            <Modal contentClassName="shadow-theme-lg rounded-5 p-5 border-0"
                centered backdrop={false} show={modal.visible}>
                <Modal.Body className="p-0" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="">
                        <h2 className="text-theme mb-4">Notifications</h2>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <div className="">
                            <div className="mb-3 form-check ms-1">
                                <input type="radio" className="form-check-input shadow-none" onChange={() => setNotificationsEnabled(!notificationsEnabled)} id="enableCheckBox" checked={notificationsEnabled} />
                                <label className="form-check-label" htmlFor="enableCheckBox">Enabled</label>
                            </div>
                            <div className="mb-3 form-check ms-1">
                                <input type="radio" className="form-check-input shadow-none" id="disableCheckBox" onChange={() => setNotificationsEnabled(!notificationsEnabled)} checked={!notificationsEnabled} />
                                <label className="form-check-label" htmlFor="disableCheckBox">Disabled</label>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-4 w-100 gy-3">
                        <div className="col-md-12 p-0">
                            <button type="button" onClick={updateNotificationsStatus} className="btn btn-theme w-100">Update</button>
                        </div>
                        <div className="col-md-12 p-0">
                            <button type="button" className="btn btn-white w-100" onClick={onCancel}>Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}




export default NotificationStatusModal;



import AWS from 'aws-sdk'



const AccessKeyId = "AKIASJRJLHHCP7GB5TVF";
const SecretAccessKey = "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";
// const REGION = "ap-south-1"
const REGION = "us-east-2"
// const BUCKET = "kistt-mi6"
const BUCKET = "kisttapp"



export default async function UploadImagesToS3(modelId, images, creds, filenames = []) {
    AWS.config.update({
        region: REGION,
        signatureVersion: "v4",
        s3: {
            credentials: {
                accessKeyId: creds.AccessKeyId,
                secretAccessKey: creds.SecretAccessKey,
                sessionToken: creds.SessionToken,
                expireTime: new Date(creds.Expiration),
            }
        }
    });

    const s3Client = new AWS.S3();
    const uploadedImages = []
    uploadedImages.length = images.length;
    try {
        const promises = [];
        for (let i = 0; i < images.length; i++) {
            var filename = filenames.length > i ? filenames[i] : generateFilename();
            const file = new File([images[i].blob], filename);
            const promise = s3Client.upload({
                Bucket: BUCKET,
                Key: "Model00" + modelId + "/" + filename,
                ContentType: "image/png",
                Body: file,
            }).promise().then((res) => {
                uploadedImages[i] = res.Key;
            });
            promises.push(promise);
        }
        await Promise.all(promises);
        console.log("Photos uploaded to S3 successfully");
        return { uploaded: true, uploadedImages: uploadedImages };
    } catch (error) {
        console.log(error)
        return { uploaded: false, uploadedImages: [] };
    }
}


function generateFilename() {
    return new Date().toISOString()
        .replace("T", "-")
        .replace(":", "-")
        .replace(".", "-")
        .replace("Z", "")
        .concat(".png")
}


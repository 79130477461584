import axios from "axios";
import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URL } from "./Constants";



export const getFbUser = async (access_token) => {
    const fbUser = await axios
        .get(
            `https://graph.facebook.com/me`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    "Content-Type": "application/json",

                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            console.error(`Failed to fetch user`);
            throw new Error(error.message);
        });
    return fbUser;
}


export const getGoogleUser = async (access_token) => {
    const userInfoUrl = "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + access_token;
    const googleUser = await axios.get(userInfoUrl)
        .then((res) => res.data)
        .catch((error) => {
            console.error(`Failed to fetch user`);
            throw new Error(error.message);
        });
    return googleUser;
}



// const ROOT_GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth`;
// export const getGoogleAuthUrl = () => {
//     const options = {
//         redirect_uri: GOOGLE_REDIRECT_URL,
//         client_id: GOOGLE_CLIENT_ID,
//         response_type: "token",
//         scope: [
//             "https://www.googleapis.com/auth/userinfo.profile",
//             "https://www.googleapis.com/auth/userinfo.email",
//         ].join(" ")
//     };
//     const qs = new URLSearchParams(options);
//     return `${ROOT_GOOGLE_AUTH_URL}?${qs.toString()}`;
// };


import axios from "axios";
import useAuth from "./AuthHooks";
import { API_ADD_MODEL_PATH, API_CREATE_MODEL_PATH, API_DELETE_MODEL_PATH, API_MODEL_LISTING_PATH, API_REMOVE_MODEL_IMAGE_PATH, API_UPDATE_MODEL_IMAGE_PATH } from "./Constants";





export async function Create3DModel(accessToken, modelId) {
    try {
        let response = await axios.post(
            API_CREATE_MODEL_PATH,
            { model_id: modelId },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        if (response.status === 200) {
            console.log("Create Model Response");
            console.log(response.data);
            return response.data.data;
        }
    } catch (error) {
        console.log(error.response)
    }
    return null;
}





export async function Delete3DModel(accessToken, modelId) {
    try {
        let response = await axios.post(
            API_DELETE_MODEL_PATH,
            { model_id: modelId },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        console.log("Delete Model Response");
        console.log(response.data);
        return { success: response.data.success, response: response.data.message };
    } catch (error) {
        console.log(error.response)
        return { success: error.response.data.success, response: error.response.data.message }
    }
    return { success: false, response: "" };
}




export async function AddNewModel(accessToken, modelName) {
    try {
        let response = await axios.post(
            API_ADD_MODEL_PATH,
            { "model_name": modelName },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        if (response.status === 200) {
            console.log("Add Model Response");
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.log(error.response)
        return error.response.data;
    }
    return null;
}



export async function GetAllModels(accessToken) {
    try {
        let response = await axios.get(
            API_MODEL_LISTING_PATH,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                },
            },
        )
        if (response.status === 200) {
            return response.data.dataListing;
        }
    } catch (error) {
        console.log(error.response)
    }
    return null;
}


export async function GetModelById(accessToken, modelId) {
    let _models = await GetAllModels(accessToken);
    if (_models !== null && _models.length >= 1) {
        for (let i = 0; i < _models.length; i++) {
            const model = _models[i];
            if (model.id === modelId) return model;
        }
    }
    return null;
}



export async function AddImagesToModel(accessToken, modelId, images) {
    try {
        let response = await axios.post(
            API_ADD_MODEL_PATH,
            {
                "model_id": modelId,
                "image": JSON.stringify(images),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        if (response.status === 200) {
            console.log("Add Model Response");
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        return error.response.data;
    }
    return null;
}






export async function UpdateImageInModel(accessToken, imageId, newImage) {
    try {
        let response = await axios.post(
            API_UPDATE_MODEL_IMAGE_PATH,
            {
                image_id: imageId,
                image: newImage,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        if (response.status === 200) {
            console.log("Update Image Response");
            console.log(response.data);
            return response.data.data;
        }
    } catch (error) {
        console.log(error.response)
    }
    return null;
}




export async function RemoveImageFromModel(accessToken, imageId) {
    try {
        let response = await axios.post(
            API_REMOVE_MODEL_IMAGE_PATH,
            { "image_id": imageId },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                }
            }
        )
        console.log("Delete Image Response");
        console.log(response.data);
        return response.status === 200;
    } catch (error) {
        console.log(error.response)
    }
    return false;
}




import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";




function AskUploadOrCaptureModal({ modal, onSuccess, onCancel }) {
    return (
        <Modal dialogClassName="objUpload"
            contentClassName="shadow-theme-lg"
            centered backdrop={false} show={modal.visible}>
            <Modal.Body>
                <div className="content">
                    <h2 className="text-theme mb-4">Upload Or Capture Images</h2>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 p-0 pt-4">
                <div className="row m-0 w-100 gy-3">
                    <div className="col-md-4">
                        <button type="button" className="btn btn-white w-100" onClick={onCancel} >Cancel</button>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-theme w-100" onClick={() => onSuccess("upload")}>Uplaod</button>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-theme w-100" onClick={() => onSuccess("capture")}>Capture</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    );
}




export default AskUploadOrCaptureModal;



import React from "react";



function Footer() {
    return (
        <div></div>
    );
}

export default Footer;
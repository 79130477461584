import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_CHANGE_PASSWORD_PATH, TIME_TO_STAY_BEFORE_REDIRECT } from '../utils/Constants';
import PasswordInput from '../components/PasswordInput';
import useAuth from '../utils/AuthHooks';


function ChangePasswordPage() {
    const { accessToken } = useAuth();
    const navigate = useNavigate("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState("");
    const [message, setMessage] = useState({ visible: false, text: "", success: false });
    const [messageTimout, setMessageTimout] = useState(null);


    useEffect(() => {
        if (message.visible) {
            if (messageTimout !== null) clearTimeout(messageTimout)
            setMessageTimout(
                setTimeout(() => {
                    setMessage({
                        visible: false,
                        text: "",
                        success: false,
                    })
                }, 4000)
            )
        }
    }, [message])


    async function processChangePassword() {
        if (currentPassword.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") return;
        if (newPassword !== confirmPassword) {
            setMessage({
                visible: true,
                text: "The password confirmation does not match.",
                success: false
            })
            return
        }
        if (currentPassword === newPassword) {
            setMessage({
                visible: true,
                text: "New password must be different from the current password.",
                success: false
            })
            return
        }

        setLoading(true);
        const body = {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
        }
        var response = null;
        try {
            response = await axios.post(
                API_CHANGE_PASSWORD_PATH,
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                },
            )

        } catch (error) {
            response = error.response
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
            }
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setLoading(false)
        }

    }


    return (
        <>
            <div className="auth-lady">
                <img src="/images/auth-lady.png" alt="auth-lady" />
            </div>
            <main>
                <div className="content text-center">
                    <Link to="/" className='text-decoration-none'><h1 className="text-white">KISTT</h1></Link>
                </div>
                <div className="auth-form" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="content text-center text-theme">
                        <h2>Change Password</h2>
                    </div>
                    <form>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <PasswordInput value={currentPassword} onChange={setCurrentPassword} className="my-4" placeholder={"Current Password"} />
                        <PasswordInput value={newPassword} onChange={setNewPassword} className="my-4" placeholder={"New Password"} />
                        <PasswordInput value={confirmPassword} onChange={setConfirmPassword} className="my-4" placeholder={"Confirm Password"} />
                        <div className='mt-md-5 mt-3'></div>
                        <button type="button" onClick={processChangePassword} disabled={loading} className="btn btn-theme w-100">
                            {
                                loading ?
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    : <span>Continue</span>
                            }
                        </button>
                    </form>
                </div>
            </main>
        </>
    );
}


export default ChangePasswordPage;
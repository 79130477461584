import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Preview3DImageModal from "./Preview3DImageModal";
import axios from "axios";
import { API_CREATE_MODEL_PATH } from "../utils/Constants";
import useAuth from "../utils/AuthHooks";



function ProcessingImagesModal({ modal, onCancel }) {
    const navigate = useNavigate();
    const { accessToken } = useAuth();


    useEffect(() => {
        if (modal.visible && modal.model != null) {
            axios.post(
                API_CREATE_MODEL_PATH,
                {
                    model_id: modal.model.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + accessToken,
                    }
                }
            ).then((response) => {
                console.log("Create Model Response");
                console.log(response.data);
                if (response.status === 200) {
                    navigate("/photos");
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
        }

    }, [modal.visible])

    return (
        <>
            <Modal
                fullscreen={true}
                scrollable={true}
                contentClassName="processing_images_component text-center shadow-theme-lg"
                centered backdrop={false} show={modal.visible}>
                <Modal.Header className="border-0">
                    <Link to="/" reloadDocument={true} id="close_btn"
                        className="btn text-light ms-0"
                        onClick={(e) => {
                            if (window.confirm("All unsaved data will be deleted. Do you want want to continue to close?")) {
                                onCancel()
                            } else {
                                e.preventDefault()
                            }
                        }}
                        aria-label="Close">
                        <i className="fa-solid fa-xmark fs-4"></i>
                    </Link>
                </Modal.Header>
                <Modal.Body className="modal-body body d-flex flex-column align-items-center">
                    <img src="/images/processing_image.svg" className="img-fluid" width="555" height="390" alt="" />
                    <h4 className="text-light">Processing your Photos</h4>
                    {
                        modal.model !== null &&
                        <div className="d-flex align-items-center gap-4 justify-content-center flex-wrap" style={{ maxWidth: "600px" }}>
                            {
                                modal.model.images.map((img) => (
                                    <img key={img.id} src={img.path} className="rounded small_preview_image" width="100" height="100" alt="" />
                                ))
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal >
        </>
    );
}


export default ProcessingImagesModal;

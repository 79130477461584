



export const EMAIL_INVALID_TEXTS = "Invalid email address. Please provide a valid email address.";
export const API_BASE_PATH = "https://pro.kistt.app/api"
export const API_LOGIN_PATH = API_BASE_PATH + "/login"
export const API_SOCIAL_LOGIN_PATH = API_BASE_PATH + "/social-login"
export const API_SIGNUP_PATH = API_BASE_PATH + "/signup"
export const API_FORGOT_PASSWORD_PATH = API_BASE_PATH + "/forgot-password"
export const API_CHANGE_PASSWORD_PATH = API_BASE_PATH + "/change-password"
export const API_NOTIFICATION_STATUS_PATH = API_BASE_PATH + "/notification-status"
export const API_MODEL_LISTING_PATH = API_BASE_PATH + "/model-listing"
export const API_UPDATE_PROFILE_PATH = API_BASE_PATH + "/update-profile"
export const API_PROFILE_DETAIL_PATH = API_BASE_PATH + "/profile-detail"
export const API_S3_CREDENTIALS_PATH = API_BASE_PATH + "/s3-credentials"
export const API_ADD_MODEL_PATH = API_BASE_PATH + "/add-model"
export const API_UPDATE_MODEL_IMAGE_PATH = API_BASE_PATH + "/update-image"
export const API_REMOVE_MODEL_IMAGE_PATH = API_BASE_PATH + "/image-remove"
export const API_CREATE_MODEL_PATH = API_BASE_PATH + "/create-model"
export const API_DELETE_MODEL_PATH = API_BASE_PATH + "/model-remove"
export const TIME_TO_STAY_BEFORE_REDIRECT = 1000;




export const FACEBOOKE_APP_ID = "1238842433734839"
export const GOOGLE_CLIENT_ID = "424075640641-267c6ganc4qsdea8u1krn9qp8tluevlv.apps.googleusercontent.com"



export const GOOGLE_SOCIAL_LOGIN_TYPE = 2;
export const FACEBOOK_SOCIAL_LOGIN_TYPE = 1;




//Invite A Friend Link Details
export const SHARE_APP_LINK = "http://play.google.com/store/apps/details?id=com.kistt"
// export const SHARE_APP_TITLE = "Kistt - The Great 3D App"
export const SHARE_APP_BODY = "Kistt - The Great 3D App \n Install this cool application:"


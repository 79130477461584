import React, { useEffect, useState } from "react";
import AskModelNameModal from "../modals/AskModelNameModal";
import CaptureImagesModal from "../modals/CaptureImagesModal";
import UploadImagesModal from "../modals/UploadImagesModal";


const imagesSelectionBoxes = [
    {
        "name": "Small",
        "imgSrc": "/images/ring.png",
    },
    {
        "name": "Medium",
        "imgSrc": "/images/shoes.png",
    },
    {
        "name": "Large",
        "imgSrc": "/images/furniture.png",
    },
    {
        "name": "Extra Large",
        "imgSrc": "/images/car.png",
    },

]


function HomePage() {
    const [askModelNameModal, setAskModelNameModal] = useState({ visible: false });
    const [captureImagesModal, setCaptureImagesModal] = useState({ visible: false, model: null });
    const [uploadImagesModal, setUploadImagesModal] = useState({ visible: false, model: null });


    return (
        <>
            <main className={
                captureImagesModal.visible
                    || askModelNameModal.visible
                    || uploadImagesModal.visible ? "blur" : ""
            }>
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="content">
                                <h2>Select the size of the image</h2>
                            </div>
                            <div className="size-boxes">
                                <div className="content">
                                    <p>Select the size of the image you plan on photographing.</p>
                                </div>
                                <div className="box-listing row row-cols-2 g-xl-5 g-2 g-md-3">
                                    {
                                        imagesSelectionBoxes.map((boxItem) => (
                                            <div className="col" key={boxItem.name}
                                                onClick={() => setAskModelNameModal({ visible: true })}>
                                                <div
                                                    className="cursor-pointer d-flex flex-column flex-xl-row gap-3 gap-lg-5 shadow-theme-sm align-items-center text-center text-xl-start p-xl-5 px-1 px-md-3 py-2 py-md-4 h-100 rounded-5"
                                                    style={{}}>
                                                    <div className="img-container col-12 col-lg-5 px-5 px-md-4 px-lg-2 px-xl-3 pb-lg-0">
                                                        <img src={boxItem.imgSrc}
                                                            style={{ width: "100%", objectFit: "contain", objectPosition: "center", aspectRatio: 1 }}
                                                            className="img-fluid" alt="..." />
                                                    </div>
                                                    <div className="body col-12 col-lg-7">
                                                        <h3 className="fw-semibold">{boxItem.name}</h3>
                                                        <p>Example: Jewelry</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
            <AskModelNameModal
                modal={askModelNameModal}
                onSuccess={(action, createdModel) => {
                    if (createdModel !== null) {
                        if (action === "capture") {
                            setCaptureImagesModal({ visible: true, model: createdModel });
                        } else {
                            setUploadImagesModal({ visible: true, model: createdModel });
                        }
                        setAskModelNameModal({ visible: false, });
                    }
                }}
                onCancel={() => setAskModelNameModal({ visible: false })} />
            <CaptureImagesModal
                modal={captureImagesModal}
                onSuccess={() => {
                    setCaptureImagesModal({ visible: false, model: null });
                }}
                onCancel={() => setCaptureImagesModal({ visible: false, model: null })} />
            <UploadImagesModal
                modal={uploadImagesModal}
                onSuccess={() => {
                    setUploadImagesModal({ visible: false, model: null });
                }}
                onCancel={() => setUploadImagesModal({ visible: false, model: null })} />
        </>
    );
}

export default HomePage
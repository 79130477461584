import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import MessageComponent from "../components/MessageComponent";
import { AddImagesToModel, GetModelById, RemoveImageFromModel, UpdateImageInModel } from "../utils/ApiModelEndpoints";
import useAuth, { useS3Credentials } from "../utils/AuthHooks";
import UploadImagesToS3 from "../utils/UploadImageToS3";
import ProcessingImagesModal from "./ProcessingImagesModal";


function UpdateModelModal({ modal, onCancel }) {
    const { accessToken } = useAuth();
    const inputRef = useRef();
    const [activeModel, setActiveModel] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadingImages, setUplaodingImages] = useState(false);
    const [activeImageId, setActiveImageId] = useState(null);
    const [processingImagesModal, setProcessingImageModal] = useState({ visible: false, model: null });
    const s3Credentials = useS3Credentials();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({ text: "", success: false });




    useEffect(() => {
        let modelId = modal.modelId;
        if (modal.visible && modelId !== undefined && modelId !== null && !isNaN(modelId)) {
            GetModelById(accessToken, modelId).then((_model) => {
                if (_model !== null) {
                    setActiveModel(_model);
                }
                setLoading(false);
            });
        }
    }, [modal.visible])


    function findActiveImage() {
        if (activeImageId === null) return null;
        if (selectedImages.length <= 0 && activeModel !== null) {
            let fImages = activeModel.images.filter((img) => img.id === activeImageId);
            if (fImages.length > 0) return fImages[0];
        } else {
            let fImages = selectedImages.filter((img) => img.id === activeImageId);
            if (fImages.length > 0) return fImages[0];
        }
        return null;
    }



    async function onImagesInputChange(newImages) {
        var images = Array.from(newImages);
        if (images.length <= 0) return;
        images = images.map((img) => {
            let imageId = Date.now() + Math.random();
            return {
                "id": imageId,
                "blob": img,
                "path": window.URL.createObjectURL(img),
            }
        });
        if (activeImageId !== null) {
            updateImage(findActiveImage(), images[0]);
            setActiveImageId(null);
        }
        else setSelectedImages([...selectedImages, ...images])
    }

    function isLocalImage(image) {
        if (selectedImages.length <= 0) return false;
        let flImages = selectedImages.filter((img) => img.id === image.id);
        return flImages.length > 0;
    }


    async function updateImage(oldImage, newImage) {
        console.log(oldImage)
        setLoading(true);
        if (!isLocalImage(oldImage)) {
            let splitPath = oldImage.path.split("/");
            let filename = splitPath[splitPath.length - 2] + "/" + splitPath[splitPath.length - 1]
            let { uploaded, uploadedImages } = await UploadImagesToS3(activeModel.id, [newImage], s3Credentials, [filename]);
            if (uploaded) {
                let imageRemoved = await UpdateImageInModel(accessToken, oldImage.id, uploadedImages[0]);
                if (imageRemoved) {
                    setActiveModel(await GetModelById(accessToken, activeModel.id));
                }
            }
        } else {
            let flImages = selectedImages.map((img) => img.id === oldImage.id ? newImage : img);
            setSelectedImages(flImages);
        }
        setActiveImageId(null);
        setLoading(false);
    }


    async function removeImage(image) {
        setLoading(true);
        if (!isLocalImage(image)) {
            let imageRemoved = await RemoveImageFromModel(accessToken, image.id);
            if (imageRemoved) {
                setActiveModel(await GetModelById(accessToken, activeModel.id));
            }
        } else {
            setSelectedImages([...selectedImages.filter((it) => it.id !== image.id)]);
        }
        setActiveImageId(null);
        setLoading(false);
    }

    function cleanup() {
        setActiveImageId(null);
        setActiveModel(null);
        setSelectedImages([]);
    }


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg']
        },
        onDropAccepted: (acceptedFiles) => {
            onImagesInputChange(acceptedFiles);
        }
    })



    async function processUploadImages() {
        if (selectedImages.length <= 0 || uploadingImages) return;
        setUplaodingImages(true);
        let { uploaded, uploadedImages } = await UploadImagesToS3(activeModel.id, selectedImages, s3Credentials);
        if (uploaded) {
            let response = await AddImagesToModel(accessToken, activeModel.id, uploadedImages);
            let resModel = response.data;
            // setMessage({ text: response.message, success: response.success });
            if (resModel !== undefined && resModel !== null) {
                console.log(resModel);
                setActiveModel(resModel);
            }
            else setActiveModel(null);
            setSelectedImages([]);
        }
        setActiveImageId(null);
        setUplaodingImages(false);
    }


    return (
        <>
            <Modal dialogClassName="modal-lg"
                contentClassName="capture_image_modal_content text-center shadow-theme-lg rounded-5"
                centered backdrop={false} show={modal.visible && !processingImagesModal.visible}>
                <Modal.Header className="d-flex justify-content-between align-items-center">
                    <button type="button" className="btn" id="close_btn"
                        onClick={() => { cleanup(); onCancel(); }}
                        aria-label="Close">
                        <i className="fa-solid fs-4 fa-xmark text-dark"></i>
                    </button>
                    <h6 className="modal-title m-0 text-dark">
                        Edit Model
                    </h6>
                    <button type="button" className="btn" aria-label="Next"
                        onClick={() => {
                            if (selectedImages.length <= 0 && !uploadingImages && activeModel !== null) {
                                setProcessingImageModal({
                                    visible: true,
                                    model: activeModel,
                                })
                            }
                        }}>
                        <i className="fa-solid fs-4 fa-check text-dark"></i>
                    </button>
                </Modal.Header>
                <Modal.Body className="body py-2 px-4" style={{ background: "#FFF" }}>
                    <MessageComponent className="mt-2" message={message} setMessage={setMessage} />
                    {
                        loading
                            ? <div className="py-5 d-flex align-items-center justify-content-center">
                                <div className="spinner-border" style={{ width: 80, height: 80, aspectRatio: 1 }}></div>
                            </div>
                            : activeModel === null
                                ? <div className="py-5">
                                    <div class="alert alert-danger" role="alert">
                                        No model found for the provide ID.
                                    </div>
                                </div>
                                : <>
                                    <div className="mb-3 w-100 text-start py-4">
                                        <div {...getRootProps()} onClick={() => inputRef.current.click()}
                                            className={"cursor-pointer border border-1 d-flex flex-column align-items-center justify-content-center p-5 rounded-4 border-theme-dashed"}>
                                            <input {...getInputProps({ accept: "image/*" })} type="file" ref={inputRef}
                                                multiple={activeImageId === null}
                                                onChange={(e) => onImagesInputChange(e.target.files)}
                                                className="d-none" />
                                            <div className="text-dark text-center">
                                                <i className="fs-3 fa-solid fa-upload"></i>
                                                <p className="fs-14 mt-3" style={{ maxWidth: "350px" }}>
                                                    {
                                                        (isDragActive ? "Drop Files Here" : "Drag and drop photos here or browse")
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-2 row-cols-md-4 w-100 g-3 justify-content-center align-items-center">
                                        {activeModel !== null &&
                                            [...(activeModel.images.sort((a, b) => a.id - b.id)), ...selectedImages].map((img) => (
                                                <div className="col" key={img.id}>
                                                    <div className={"position-relative rounded-4 border border-2 shadow-simple "}
                                                        style={{ overflow: "hidden" }}>
                                                        <img src={img.path}
                                                            style={{
                                                                width: "100%", height: "auto", aspectRatio: 1,
                                                                objectFit: "cover", objectPosition: "center",
                                                                minHeight: "100px"
                                                            }}
                                                            className="rounded-3" />
                                                        <div className="position-absolute top-0 w-100 d-flex justify-content-between">
                                                            <span className="cursor-pointer rounded-1 p-1 bg-light"
                                                                onClick={() => removeImage(img)}>
                                                                <i className="fa-solid text-danger fs-14 fa-trash"></i>
                                                            </span>
                                                            <span className="cursor-pointer rounded-1 p-1 bg-light"
                                                                onClick={() => { setActiveImageId(img.id); inputRef.current.click(); }}>
                                                                <i className="fa-solid text-dark fs-14 fa-pencil"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                    }
                </Modal.Body>
                <Modal.Footer className="p-3">
                    <div className="d-flex align-items-center justify-content-between m-0 w-100">
                        <span></span>
                        <button type="button" onClick={processUploadImages}
                            disabled={selectedImages.length <= 0}
                            className="btn btn-theme">
                            {
                                uploadingImages ? <span className="spinner-border spinner-border-sm"></span>
                                    : "Upload"
                            }
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
            <ProcessingImagesModal
                modal={processingImagesModal}
                onCancel={() => setProcessingImageModal({ visible: false, model: null })}
                onSuccess={() => setProcessingImageModal({ visible: false, model: null })} />
        </>
    );
}




export default UpdateModelModal;

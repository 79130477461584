import React from "react";
import { Link } from "react-router-dom";




function Sidebar({ pathname }) {
    return (
        <div className="sidebar-content">
            <div className="sidebar-body">
                <ul className="sidebar-menu">
                    <li><Link to="/" className={"side-link " + (pathname === "/" ? "active" : "")}><i className="fa-solid fa-house"></i> Home</Link></li>
                    <li><Link to="/photos" className={"side-link " + (pathname.includes("/photos") ? "active" : "")}><i className="fa-solid fa-images"></i> 3D Photos</Link></li>
                </ul>
            </div>
            <div className="sidebar-footer">
                <ul className="sidebar-menu">
                    <li><Link to="/settings" className={"side-link " + (pathname.includes("/settings") ? "active" : "")}><i className="fa-solid fa-gear"></i>Settings</Link></li>
                    <li><Link to="/logout" className="side-link"><i className="fa-solid fa-right-from-bracket"></i>Logout</Link></li>
                </ul>
            </div>
        </div>
    );
}


export default Sidebar;

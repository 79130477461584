import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import MessageComponent from "../components/MessageComponent";
import { AddNewModel } from "../utils/ApiModelEndpoints";
import useAuth from "../utils/AuthHooks";
import { API_ADD_MODEL_PATH } from "../utils/Constants";
import AskUploadOrCaptureModal from "./AskUploadOrCaptureModal";




function AskModelNameModal({ modal, onSuccess, onCancel }) {
    const [askUploadOrCaptureModal, setAskUploadOrCaptureModal] = useState({ visible: false });
    const [modelName, setModelName] = useState("");
    const [model, setModel] = useState(null);
    const [creatingModel, setCreatingModel] = useState(false);
    const { accessToken } = useAuth();
    const [message, setMessage] = useState({ text: "", success: false });



    async function addModelToServer() {
        if (modelName === "" || creatingModel) return;
        setCreatingModel(true);
        let response = await AddNewModel(accessToken, modelName);
        if (response !== null) {
            setMessage({ text: response.message, success: response.success });
            let model = response.data;
            if (model !== undefined && model !== null) {
                setModel(model);
                setAskUploadOrCaptureModal({ visible: true });
            }

        }
        setCreatingModel(false);
    }

    return (
        <>
            <Modal dialogClassName="objUpload"
                contentClassName="shadow-theme-lg"
                centered backdrop={false} show={modal.visible}>
                <Modal.Body>
                    <div className="content">
                        <h2 className="text-theme mb-4">Create Model</h2>
                        <MessageComponent className="mt-2" message={message} setMessage={setMessage} />
                        <input type="text" className="form-control"
                            value={modelName} onChange={(e) => setModelName(e.target.value)}
                            placeholder="Enter Model Name" style={{ height: 60 }} aria-label="Input" />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 p-0 pt-4">
                    <div className="row m-0 w-100">
                        <div className="col-md-6">
                            <button type="button" className="btn btn-white w-100" onClick={onCancel}>Cancel</button>
                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-theme w-100" onClick={addModelToServer}>
                                {
                                    creatingModel ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span>Create</span>
                                }
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
            <AskUploadOrCaptureModal
                modal={askUploadOrCaptureModal}
                onSuccess={(action) => {
                    setModelName("");
                    setAskUploadOrCaptureModal({ visible: false });
                    onSuccess(action, model);
                    setModelName("");
                }}
                onCancel={() => {
                    setModelName("");
                    setAskUploadOrCaptureModal({ visible: false });
                    onCancel()
                    setModelName("");
                }} />
        </>
    );
}




export default AskModelNameModal;



import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { API_PROFILE_DETAIL_PATH, API_S3_CREDENTIALS_PATH } from "./Constants";




export default function useAuth() {
    const navigate = useNavigate();
    const { props } = useOutletContext();
    // const [user, setUser] = useState(() => {
    //     axios.get(
    //         API_PROFILE_DETAIL_PATH,
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Bearer " + accessToken
    //             }
    //         }
    //     ).then((response) => {
    //         if (response.status == 200) {
    //             sessionStorage.setItem("user", JSON.stringify(response.data.data))
    //             return response.data.data;
    //         }
    //     }).catch((error) => {
    //         console.log(error.response);
    //         navigate("/login");
    //     })
    // })
    const { user, accessToken } = props;
    useEffect(() => {
        if (accessToken === null || accessToken === undefined || user === null || user === undefined) {
            navigate("/login?callback=/");
        }
    }, [props])
    return { user, accessToken };
}


export function useS3Credentials() {
    const { accessToken } = useAuth();
    const [creds, setCreds] = useState(() => {
        axios.get(
            API_S3_CREDENTIALS_PATH,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken,
                }
            }
        )
            .then((response) => {
                setCreds(response.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
    });

    return creds
}



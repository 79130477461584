import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_UPDATE_PROFILE_PATH, EMAIL_INVALID_TEXTS, TIME_TO_STAY_BEFORE_REDIRECT } from "../utils/Constants";
import useAuth from "../utils/AuthHooks";
import Cookies from "universal-cookie";




function UpdateProfileModal({ modal, onCancel, onSuccess }) {
    const cookies = new Cookies();
    const { user, accessToken } = useAuth();
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.name);
    const [profileImage, setProfileImage] = useState({ url: user.image, blob: null });
    const profileImageRef = useRef();
    const [loading, setLoading] = useState("");
    const [message, setMessage] = useState({ visible: false, text: "", success: false });
    const [hasChanges, setHasChanges] = useState(false);



    useEffect(() => {
        setHasChanges(email !== user.email || name !== user.name || profileImage.url !== user.image)
    }, [name, email, profileImage])


    function isValidEmail(value) {
        return /\S+@\S+\.\S+/.test(value);
    }



    function onEmailChange(e) {
        setEmail(e.target.value);
        const emailValid = isValidEmail(e.target.value)
        setMessage({
            visible: !emailValid,
            text: EMAIL_INVALID_TEXTS,
            success: emailValid,
        })
    }


    function onProfileImageChange(e) {
        const files = e.target.files;
        if (files.length <= 0) return;
        setProfileImage({
            url: window.URL.createObjectURL(files[0]),
            blob: files[0],
        });
    }


    async function processProfileUpdate() {
        if (name === "" || email === "" || !isValidEmail(email)) return;
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        if (profileImage.url !== user.image) formData.append("image", profileImage.blob);
        var response = null;
        try {
            response = await axios.post(
                API_UPDATE_PROFILE_PATH,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + accessToken,
                    },
                },
            )
            console.log(response)
        } catch (error) {
            console.log(error.response)
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
                if (response.status === 200) {
                    let expireDate = new Date()
                    console.log(response.data)
                    expireDate.setDate(expireDate.getDate() + 1);
                    cookies.set(
                        "user", response.data.data,
                        {
                            secure: true, path: "*",
                            sameSite: "strict", expires: expireDate,
                            maxAge: 60 * 60 * 24,
                        }
                    )
                    setEmail("");
                    setName("");
                    setTimeout(() => {
                        window.location.reload();
                    }, TIME_TO_STAY_BEFORE_REDIRECT);
                }
            }
            setLoading(false)
        }

    }


    return (
        <Modal contentClassName="text-center shadow-simple-lg"
            centered backdrop={false} show={modal.visible}>
            <Modal.Header className="d-flex align-items-center justify-content-center p-2">
                <h2 className="fs-2">Profile</h2>
            </Modal.Header>
            <Modal.Body className="pt-4 pb-5 px-3 px-md-5">
                <div className="" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="mb-4 mx-auto" style={{ width: "min-content" }}>
                        <input type="file" ref={profileImageRef} onChange={onProfileImageChange} accept='image/*' className='d-none' />
                        <img src={profileImage.url} width={120} height={120}
                            onClick={() => profileImageRef.current.click()}
                            className="rounded-circle bg-light border border-4 cursor-pointer"
                            style={{ objectFit: "cover", objectPosition: "center" }} />
                    </div>
                    <form>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3 my-4">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }} >
                                <i className="fa-solid fa-user"></i>
                            </span>
                            <input type="text"
                                value={name} onChange={(e) => setName(e.target.value)}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Full Name" aria-label="Full Name" />
                        </div>
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }} >
                                <i className="fa-solid fa-envelope"></i>
                            </span>
                            <input type="text"
                                value={email} onChange={onEmailChange}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Email Address" aria-label="Email Address" />
                        </div>

                        <div className='mt-md-5 mt-3'></div>
                        <div className="row m-0 w-100 gx-0 gy-3 gx-md-3">
                            <div className="col-md-6">
                                <button type="button" className="btn btn-white w-100" onClick={onCancel}>Cancel</button>
                            </div>
                            <div className="col-md-6">
                                <button type="button" onClick={processProfileUpdate} disabled={loading || !hasChanges} className="btn btn-theme w-100">
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span>Update Profile</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal >
    );
}


export default UpdateProfileModal;
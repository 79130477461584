import React, { useEffect, useState } from "react";


function MessageComponent({ message, setMessage, className }) {
    const [messageTimeout, setMessageTimeout] = useState(null);



    useEffect(() => {
        if (message.text !== "") {
            if (messageTimeout !== null) clearTimeout(messageTimeout)
            setMessageTimeout(
                setTimeout(() => {
                    setMessage({
                        text: "",
                        success: false,
                    })
                }, 5000)
            )
        }
    }, [message]);


    if (message.text !== "") return (
        <div className={"alert " + (message.success ? "alert-success" : "alert-danger") + " " + className} role="alert">
            <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
            <span>{message.text}</span>
        </div>
    );
    else return <></>
}

export default MessageComponent;
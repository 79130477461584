import React from "react";
import Sidebar from "./Sidebar";



function Header({ pathname }) {
    return (
        <div className="sidebar">
            <Sidebar pathname={pathname} />
        </div>
    );
}

export default Header;
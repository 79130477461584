import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_LOGIN_PATH, API_SOCIAL_LOGIN_PATH, EMAIL_INVALID_TEXTS, FACEBOOK_SOCIAL_LOGIN_TYPE, GOOGLE_CLIENT_ID, GOOGLE_SOCIAL_LOGIN_TYPE, TIME_TO_STAY_BEFORE_REDIRECT } from '../utils/Constants';
import PasswordInput from '../components/PasswordInput';
import Cookies from 'universal-cookie';
import { LoginStatus, useLogin, useProfile, } from 'react-facebook';
import { getFbUser, getGoogleUser } from '../utils/SocialAuth';




function LoginPage() {
    const cookies = new Cookies();
    const callbackUrl = useSearchParams({ "callback": "/" })[0].get("callback");
    const navigate = useNavigate("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState("");
    const [message, setMessage] = useState({ visible: false, text: "", success: false })
    const [messageTimout, setMessageTimout] = useState(null);
    const facebookLogin = useLogin();



    useEffect(() => {
        if (message.visible) {
            if (messageTimout !== null) clearTimeout(messageTimout)
            setMessageTimout(
                setTimeout(() => {
                    setMessage({
                        visible: false,
                        text: "",
                        success: false,
                    })
                }, 4000)
            )
        }
    }, [message]);


    function isValidEmail(value) {
        return /\S+@\S+\.\S+/.test(value);
    }


    function onEmailChange(e) {
        setEmail(e.target.value);
        const emailValid = isValidEmail(e.target.value)
        setMessage({
            visible: !emailValid,
            text: EMAIL_INVALID_TEXTS,
            success: emailValid,
        })
    }


    function continueWithGoogle() {
        setLoading(true);
        const client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: [
                "https://www.googleapis.com/auth/userinfo.profile",
                "https://www.googleapis.com/auth/userinfo.email",
            ].join(" "),
            ux_mode: 'popup',
            callback: async (response) => {
                console.log(response)
                const googleUser = await getGoogleUser(response.access_token)
                if (googleUser !== null) {
                    console.log(googleUser);
                    _handleLogin({
                        type: GOOGLE_SOCIAL_LOGIN_TYPE,
                        google_id: googleUser.sub,
                        name: googleUser.name,
                        email: googleUser.email,
                    }, API_SOCIAL_LOGIN_PATH);
                } else {
                    setMessage({
                        text: "Got error while authenticating with google.",
                        success: false,
                    })
                }
            }
        });
        client.requestAccessToken({ prompt: "consent" });
    }


    async function continueWithFacebook() {
        setLoading(true);
        try {
            const response = await facebookLogin.login({
                scope: 'email,public_profile',
                returnScopes: true
            });
            console.log(response);
            if (response.status === LoginStatus.CONNECTED) {
                let fbUser = await getFbUser(response.authResponse.accessToken);
                _handleLogin({
                    type: FACEBOOK_SOCIAL_LOGIN_TYPE,
                    facebook_id: response.authResponse.userID,
                    name: fbUser.name,
                }, API_SOCIAL_LOGIN_PATH);
            } else {
                setMessage({
                    text: "Got error while authenticating with facebook.",
                    success: false,
                })
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    async function _handleLogin(body, path) {
        var response = null;
        try {
            response = await axios.post(
                path, body,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                },
            )

        } catch (error) {
            response = error.response
        }
        finally {
            if (response != null) {
                setMessage({
                    visible: true,
                    text: response.data.message,
                    success: response.status === 200
                })
                if (response.status === 200) {
                    setEmail("");
                    let expireDate = new Date()
                    expireDate.setDate(expireDate.getDate() + 1);
                    cookies.set(
                        "user", response.data.data,
                        {
                            secure: true, path: "*",
                            sameSite: "strict", expires: expireDate,
                            maxAge: 60 * 60 * 24,
                        }
                    )
                    cookies.set(
                        "access_token", response.data.data.access_token,
                        {
                            secure: true, path: "*",
                            sameSite: "strict", expires: expireDate,
                            maxAge: 60 * 60 * 24,
                        }
                    )
                    setTimeout(() => {
                        navigate(callbackUrl);
                    }, TIME_TO_STAY_BEFORE_REDIRECT);
                }
            }
            setPassword("");
            setLoading(false)
        }
    }


    async function processLogin() {
        if (email === "" || password === "" || !isValidEmail(email)) return;
        setLoading(true);
        const body = {
            email: email,
            password: password,
        }
        _handleLogin(body, API_LOGIN_PATH);
    }


    return (
        <>
            <div className="auth-lady">
                <img src="./images/auth-lady.png" alt="auth-lady" />
            </div>
            <main>
                <div className="content text-center">
                    <Link to="/" className='text-decoration-none'><h1 className="text-white">KISTT</h1></Link>
                </div>
                <div className="auth-form" style={{ opacity: loading ? .7 : 1, pointerEvents: loading ? "none" : "auto" }}>
                    <div className="content text-center text-theme">
                        <h2>Welcome!</h2>
                        <p>Login to continue</p>
                    </div>
                    <form>
                        {
                            message.visible &&
                            <div className={"alert " + (message.success ? "alert-success" : "alert-danger")} role="alert">
                                <i className={"fa-solid me-3 " + (message.success ? "fa-circle-check" : "fa-triangle-exclamation")}></i>
                                <span>{message.text}</span>
                            </div>
                        }
                        <div className="input-group flex-nowrap shadow-simple p-3 rounded-3">
                            <span className="input-group-text text-muted border-0 bg-transparent" style={{ width: "35px" }} >
                                <i className="fa-solid fa-envelope"></i>
                            </span>
                            <input type="email"
                                value={email} onChange={onEmailChange}
                                className="form-control text-muted border-0 shadow-none"
                                placeholder="Email address" aria-label="Email address" />
                        </div>
                        <PasswordInput value={password} onChange={setPassword} className="my-4" placeholder={"Password"} />
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-3 form-check ms-1">
                                    <input type="radio" className="form-check-input shadow-none" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <Link to="/forgot-password"><strong>Forgot password?</strong></Link>
                            </div>
                        </div>
                        <div className='mt-md-5 mt-3'></div>
                        <button type="button" onClick={processLogin} disabled={loading} className="btn btn-theme w-100">
                            {
                                loading ?
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    : <span>Log In</span>
                            }
                        </button>
                    </form>
                    <div className="other-signin text-center">
                        <Link to="/signup"><strong>New user? Signup</strong></Link>
                        <p className="mt-4">By signing up you indicate that you have read and <br />agreed to the Terms of Service</p>
                        <div className="or-section">
                            <p>Or continue with</p>
                            <ul>
                                <li><a onClick={continueWithGoogle} style={{ cursor: "pointer" }}><img src="./images/google.png" alt="google" /></a></li>
                                <li><a onClick={continueWithFacebook} style={{ cursor: "pointer" }}><img src="./images/facebook.png" alt="facebook" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}


export default LoginPage;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationStatusModal from "../modals/NotificationStatusModal";
import UpdateProfileModal from "../modals/UpdateProfileModal";
import { SHARE_APP_BODY, SHARE_APP_LINK } from "../utils/Constants";
import shareContent from "../utils/ShareContent";
import useAuth from "../utils/AuthHooks";





function SettingsPage() {
    const { user } = useAuth();
    const [editProfileVisible, setEditProfileModalVisible] = useState(false);
    const [notificationsModal, setNotificationsModal] = useState({ visible: false, enabled: user && user.notification_status });

    return (
        <>
            <main className={"bg-dark " + (editProfileVisible || notificationsModal.visible ? "blur" : "")}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content">
                                <h2>Settings</h2>
                            </div>

                            <div className="accordion mt-5 setting-tabs" id="accordionExample">
                                <div className="accordion-item cursor-pointer" onClick={() => setEditProfileModalVisible(true)}>
                                    <div className="accordion-header" id="headingOne">
                                        <div className="header-detail">
                                            <div className="profile-avatar">
                                                <img src={user.image} className="rounded-circle border border-2" alt="User Profile Image" />
                                            </div>
                                            <div className="profile-detail">
                                                <h3>{user.name}</h3>
                                                <p className="m-0">{user.email}</p>
                                            </div>
                                        </div>
                                        <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="fa-sharp fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item cursor-pointer" onClick={() => setEditProfileModalVisible(true)}>
                                    <div className="accordion-header" id="headingTwo">
                                        <div className="header-detail">
                                            <div className="profile-icon">
                                                <i className="fa-sharp fa-regular fa-user"></i>
                                            </div>
                                            <div className="profile-detail">
                                                <h4>Profile Settings</h4>
                                            </div>
                                        </div>
                                        <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <i className="fa-sharp fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item cursor-pointer" onClick={() => setNotificationsModal({ visible: true, enabled: notificationsModal.enabled })}>
                                    <div className="accordion-header" id="headingThree">
                                        <div className="header-detail">
                                            <div className="profile-icon">
                                                <i className="fa-sharp fa-regular fa-bell"></i>
                                            </div>
                                            <div className="profile-detail">
                                                <h4>Notification</h4>
                                            </div>
                                        </div>
                                        <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <i className="fa-sharp fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <Link to={"/settings/change-password"} className="text-decoration-none">
                                        <div className="accordion-header" id="headingFour">
                                            <div className="header-detail">
                                                <div className="profile-icon">
                                                    <i className="fa-solid fa-lock"></i>
                                                </div>
                                                <div className="profile-detail">
                                                    <h4>Change Password</h4>
                                                </div>
                                            </div>
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <i className="fa-sharp fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                    </Link>
                                </div>

                                <div className="accordion-item cursor-pointer" onClick={() => shareContent(
                                    SHARE_APP_LINK,
                                    SHARE_APP_BODY
                                )}>
                                    <div className="accordion-header" id="headingFive">
                                        <div className="header-detail">
                                            <div className="profile-icon">
                                                <i className="fa-solid fa-user-plus"></i>
                                            </div>
                                            <div className="profile-detail">
                                                <h4>Invite Friend</h4>
                                            </div>
                                        </div>
                                        <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <i className="fa-sharp fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <UpdateProfileModal
                modal={{ visible: editProfileVisible, user: user }}
                onCancel={() => setEditProfileModalVisible(false)} />
            <NotificationStatusModal
                modal={notificationsModal}
                onCancel={() => setNotificationsModal({ visible: false })} />
        </>
    );
}


export default SettingsPage;


import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';


function ProtectedLayout() {
    const cookies = new Cookies();
    const { pathname } = useLocation();
    const [accessToken, setAccessToken] = useState(() => {
        try {
            return cookies.get("access_token", { doNotParse: true });
        } catch (e) {
            return null
        }
    });
    const [user, setUser] = useState(() => {
        try {
            return cookies.get("user");
        } catch (e) {
            return null
        }
        // async function fetchUserProfile() {
        //     try {
        //         let response = await axios.get(
        //             API_PROFILE_DETAIL_PATH,
        //             {
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                     "Authorization": "Bearer " + accessToken
        //                 }
        //             }
        //         )
        //         if (response.status == 200) {
        //             sessionStorage.setItem("user", JSON.stringify(response.data.data))
        //             return response.data.data;
        //         }
        //     } catch (error) {
        //         console.log(error.response);
        //         navigate("/login");
        //     }
        // }
        // let sessionUser = sessionStorage.getItem("user");
        // console.log(sessionUser);
        // if (sessionUser !== null && sessionUser !== "undefined") return JSON.parse(sessionUser);
        // else fetchUserProfile();
    });

    return (
        (accessToken !== null && accessToken !== undefined) ? <Outlet context={{ props: { user, accessToken } }} /> : <Navigate to={'/login?callback=' + pathname} />
    )
}

export default ProtectedLayout;
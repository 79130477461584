
import "bootstrap/dist/css/bootstrap.min.css"
import './styles/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { FacebookProvider } from "react-facebook";
import { FACEBOOKE_APP_ID } from "./utils/Constants";




// function handleGoogleLogin(response) {
//   console.log(response);
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FacebookProvider appId={FACEBOOKE_APP_ID}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FacebookProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

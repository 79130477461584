import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import MessageComponent from "../components/MessageComponent";
import { AddImagesToModel, GetModelById, RemoveImageFromModel, UpdateImageInModel } from "../utils/ApiModelEndpoints";
import useAuth, { useS3Credentials } from "../utils/AuthHooks";
import Base64ToBlob from "../utils/Base64ToBlob";
import UploadImagesToS3 from "../utils/UploadImageToS3";
import ProcessingImagesModal from "./ProcessingImagesModal";


function CaptureImagesModal({ modal, onCancel }) {
    const { accessToken } = useAuth();
    const webCamRef = useRef(null);
    const bodyRef = useRef(null);
    const canvasRef = useRef(null);
    const footerImagesListRef = useRef(null);
    const [activeModel, setActiveModel] = useState(null);
    const [capturedImages, setCapturesImages] = useState([]);
    const [uploadingImages, setUplaodingImages] = useState(false);
    const s3Credentials = useS3Credentials();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ text: "", success: false });


    const videoConstraints = {
        width: 350,
        height: 350,
        facingMode: {
            ideal: "environment"
        }
    };
    const [processingImagesModal, setProcessingImageModal] = useState({ visible: false, model: null });


    function onCapture(base64) {
        // const previewWidth = bodyRef.current.offsetWidth;
        // const captureSize = canvasRef.current.offsetWidth;
        // const captureX = (previewWidth - captureSize) / 2;
        // const captureY = (previewWidth - captureSize) / 2;
        // const canvas = canvasRef.current;
        // const video = webCamRef.current.video;
        // const context = canvas.getContext('2d');
        // context.drawImage(
        //     video,
        //     captureX,
        //     captureY,
        //     captureSize,
        //     captureSize,
        //     0,
        //     0,
        //     captureSize,
        //     captureSize
        // );
        // const image = canvas.toDataURL('image/jpeg');
        // console.log(image);

        Base64ToBlob(base64, async (blob) => {
            let imageId = Date.now() + Math.random();
            const newImage = {
                "id": imageId,
                "blob": blob,
                "path": window.URL.createObjectURL(blob),
            }
            setCapturesImages([...capturedImages, newImage]);
        })
        footerImagesListRef.current.scrollTo({
            left: footerImagesListRef.current.scrollWidth,
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        setActiveModel(modal.model);
    }, [modal.visible]);



    function isLocalImage(image) {
        if (capturedImages.length <= 0) return false;
        let flImages = capturedImages.filter((img) => img.id === image.id);
        return flImages.length > 0;
    }


    async function removeImage(image) {
        setLoading(true);
        if (!isLocalImage(image)) {
            let imageRemoved = await RemoveImageFromModel(accessToken, image.id);
            if (imageRemoved) {
                setActiveModel(await GetModelById(accessToken, activeModel.id));
            }
        } else {
            setCapturesImages([...capturedImages.filter((it) => it.id !== image.id)]);
        }
        setLoading(false);
    }

    function cleanup() {
        setActiveModel(null);
        setCapturesImages([]);
    }



    async function processUploadImages() {
        if (capturedImages.length <= 0 || uploadingImages) return;
        setUplaodingImages(true);
        let { uploaded, uploadedImages } = await UploadImagesToS3(activeModel.id, capturedImages, s3Credentials);
        if (uploaded) {
            let response = await AddImagesToModel(accessToken, activeModel.id, uploadedImages);
            let resModel = response.data;
            console.log(response.message);
            // setMessage({ text: response.message, success: response.success });
            if (resModel !== undefined && resModel !== null) {
                setActiveModel(resModel);
            }
            else setActiveModel(null);
            setCapturesImages([]);
        }
        setUplaodingImages(false);
    }



    useEffect(() => {
        if (webCamRef.current != null) {
            webCamRef.current.muted = true;
        }
    }, [webCamRef]);


    return (
        <>
            <Modal dialogClassName="modal-lg"
                contentClassName="capture_image_modal_content text-center shadow-theme-lg rounded-5"
                centered backdrop={false} show={modal.visible && !processingImagesModal.visible}>
                <Modal.Header className="d-flex justify-content-between align-items-center border-1">
                    <button type="button" className="btn" id="close_btn"
                        onClick={() => { cleanup(); onCancel(); }}>
                        <i className="fa-solid fs-4 fa-xmark"></i>
                    </button>
                    <h6 className="modal-title m-0">Create Model</h6>
                    <button type="button" id="next_btn" className="btn" aria-label="Next"
                        onClick={() => {
                            if (capturedImages.length <= 0 && !uploadingImages && activeModel !== null) {
                                console.log(activeModel);
                                setProcessingImageModal({
                                    visible: true,
                                    model: activeModel,
                                })
                            }
                        }}>
                        <i className="fa-solid fs-4 fa-check"></i>
                    </button>
                </Modal.Header>
                <Modal.Body ref={bodyRef} className="body flex-column position-relative p-0 d-flex align-items-center justify-content-center">
                    <MessageComponent className="mt-2" message={message} setMessage={setMessage} />
                    {
                        loading
                            ? <div className="py-5 d-flex align-items-center justify-content-center">
                                <div className="spinner-border" style={{ width: 80, height: 80, aspectRatio: 1 }}></div>
                            </div>
                            : <>
                                <Webcam ref={webCamRef}
                                    videoConstraints={videoConstraints}
                                    className="pe-none"
                                    muted={true}
                                    audio={false}
                                    style={{ width: "100%" }}>
                                    {({ getScreenshot }) => (
                                        <button className="position-absolute"
                                            style={{ width: 60, height: 60, borderRadius: "100%", bottom: "10px" }}
                                            onClick={() => onCapture(getScreenshot())}>
                                        </button>
                                    )}
                                </Webcam>
                                {/* <canvas ref={canvasRef} className="circle_over_image"></canvas> */}
                            </>
                    }
                </Modal.Body>
                <Modal.Footer className="p-0 w-100">
                    <div className="p-2">
                        <button type="button" onClick={processUploadImages}
                            disabled={capturedImages.length <= 0}
                            className="btn btn-theme">
                            {
                                uploadingImages ? <span className="spinner-border spinner-border-sm"></span>
                                    : "Uplaod"
                            }
                        </button>
                    </div>
                    <div ref={footerImagesListRef}
                        style={{ minHeight: 80 }}
                        className="p-4 pt-2 w-100 d-flex overflow-auto no-scrollbar align-items-center gap-3 col-8">
                        {
                            activeModel !== undefined && activeModel !== null &&
                            [...(activeModel.images.sort((a, b) => a.id - b.id)), ...capturedImages].map((img) => (
                                <div key={img.id} className="position-relative shadow-theme-sm" style={{ border: "2px solid #1c96b525" }}>
                                    <img src={img.path} width={80} height={80}
                                        style={{ objectFit: "cover", objectPosition: "center" }}
                                        className="rounded-3" />
                                    <div className="position-absolute top-0 w-100 d-flex justify-content-between">
                                        <span className="cursor-pointer rounded-1 p-1 bg-transparent"
                                            onClick={() => removeImage(img)}>
                                            <i className="fa-solid text-danger fs-14 fa-trash"></i>
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Modal.Footer>
            </Modal >
            <ProcessingImagesModal
                modal={processingImagesModal}
                onCancel={() => setProcessingImageModal({ visible: false, model: null })}
                onSuccess={() => setProcessingImageModal({ visible: false, model: null })} />
        </>
    );
}


export default CaptureImagesModal;
